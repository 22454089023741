import FormContainer from 'app/components/Containers/FormContainer';
import FormWorkflowPhases from 'app/components/Forms/WorkflowPhases';
import WorkflowPhasesContext from 'app/contexts/WorkflowPhasesContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataWorkflowPhases, WorkflowPhasesRelations } from 'app/types/data/IDataWorkflowPhases';
import DisplayActivity from 'app/views/partners/activity/components/DisplayActivity';
import React from 'react';

declare interface IWorkflowPhasesFormContainer {
  data?: IDataWorkflowPhases;
  relation?: IDataRelation<WorkflowPhasesRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
}

const WorkflowPhasesFormContainer = (props: IWorkflowPhasesFormContainer) => {
  const [data, setData] = React.useState<IDataWorkflowPhases | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <WorkflowPhasesContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormWorkflowPhases
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        tabs={
          !data?.id
            ? undefined
            : [
                {
                  title: 'Atividades',
                  component: (
                    <DisplayActivity
                      displayName="Atividades"
                      relation={{ id: data.id as number, relation: 'WorkflowPhases' }}
                      view="grid"
                    />
                  ),
                },
              ]
        }
      />
    </WorkflowPhasesContext.Provider>
  );
};

export default WorkflowPhasesFormContainer;
