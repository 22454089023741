import Activity_Api from 'app/api/Activity_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import FormActivity from 'app/components/Forms/Activity';
import NotificationConfForm from 'app/components/Forms/Activity/NotificationConfForm';
import WorkflowSubActivityContext from 'app/contexts/WorkflowSubActivityContext';
import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations, IDataActivity } from 'app/types/data/IDataActivity';
import DisplayComment from 'app/views/@general/comment/components/DisplayComment';
import DisplayReasons from 'app/views/curated/reasons/components/DisplayReasons';
import React from 'react';
import DisplayActivity from '../../DisplayActivity';

declare interface ISubActivityFormContainer {
  data?: IDataActivity;
  relation?: IDataRelation<ActivityRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid: string;
}

const SubActivityFormContainer = (props: ISubActivityFormContainer) => {
  const [data, setData] = React.useState<IDataActivity | undefined>(props.data);
  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <WorkflowSubActivityContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormActivity
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
            isSub
          />
        }
        tabs={
          data && [
            {
              title: 'Sub-Atividades',
              component: (
                <DisplayActivity
                  displayName="Sub-Atividades"
                  relation={{ id: data.id as number, relation: 'Activity' }}
                  view="grid"
                />
              ),
            },
            {
              title: 'Motivos',
              component: (
                <DisplayReasons relation={{ id: data.id as number, relation: 'Activity' }} />
              ),
            },
            {
              title: 'Comentários',
              component: (
                <DisplayComment relation={{ id: data.id as number, relation: 'Activity' }} />
              ),
            },
            {
              title: 'Notificações',
              component: (
                <NotificationConfForm
                  relation={{ id: data?.id as number, relation: 'Activity' }}
                  refreshGrid={props.onSubmit}
                />
              ),
            },
            {
              title: 'Anexos',
              component: (
                <GridAttachments
                  relationData={data}
                  apiModel={Activity_Api}
                  onClickAttach={props.onClickAttach}
                />
              ),
            },
          ]
        }
      />
    </WorkflowSubActivityContext.Provider>
  );
};

export default SubActivityFormContainer;
