import FormContainer from 'app/components/Containers/FormContainer';
import FormSegments from 'app/components/Forms/Segments';
import { IDataRelation } from 'app/types/data/IData';
import { IDataSegments, SegmentsRelations } from 'app/types/data/IDataSegments';
import React from 'react';

declare interface ISegmentsFormContainer {
  data?: IDataSegments;
  relation?: IDataRelation<SegmentsRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
}

const SegmentsFormContainer = (props: ISegmentsFormContainer) => {
  const [data, setData] = React.useState<IDataSegments | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormSegments
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        /* tabs={
        data && [
          {
            title: 'Anexos',
            component: (
              <GridAttachments
                relationData={data}
                apiModel={Segments_Api}
                onClickAttach={props.onClickAttach}
              />
            ),
          },
        ]
      } */
      />
    </>
  );
};

export default SegmentsFormContainer;
