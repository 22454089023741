import { Grid } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import Orientation_Api from 'app/api/Orientation_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import {
  IDataOrientation,
  IDataOrientationErrors,
  OrientationRelations,
} from 'app/types/data/IDataOrientation';
import { IDataUser } from 'app/types/data/IDataUser';
import filterObjectColumns from 'app/utils/filterObjectColumns';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import React, { Fragment, SetStateAction } from 'react';
import { CSVLink } from 'react-csv';
import CreateAttachment from '../CreateAttachment';
import OrientationFormContainer from '../OrientationFormContainer';
import handleOnSortModelChange from 'app/utils/handleOnSortModelChange';
import selectRow from 'app/utils/selectRow';

const hiddenColumns = {
  create_user: false,
  create_user_id: false,
  update_user: false,
  update_user_id: false,
  nm_host: false,
  ds_iphost: false,
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.1, type: 'number' },
  {
    field: 'ds_orientacao',
    headerName: 'Descrição',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'nm_host',
    headerName: 'Host',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'create_user_id',
    headerName: 'Criador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'update_user_id',
    headerName: 'Atualizador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'update_user',
    headerName: 'Atualizador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

const header = columns.map((item) => item.headerName);

const UPDATE_MSG = getMessage('Orientação', 'update');
const DELETE_MSG = getMessage('Orientação', 'delete');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplayOrientation {
  relation?: IDataRelation<OrientationRelations>;
}

const DisplayOrientation = (props: IDisplayOrientation) => {
  const { handleResetForm } = useUtilsContext();
  const [isGridView, toggleView] = React.useState(true);
  const [data, setData] = React.useState<IDataOrientation[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataOrientation | undefined>(undefined);
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [openAttach, toggleAttachModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [rowStopReason, setRowStopReason] = React.useState<string>();
  const [onSaveRow, setOnSaveRow] = React.useState<string | null>(null);
  const [isLoadingAllGridRows, setLoadingAllGridRows] = React.useState<boolean>(false);
  const [allPageRows, setAllPageRows] = React.useState<any[][]>([]);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const csvLink = React.useRef<any | null>();

  const { uuid } = useTrailHistory({
    selected,
    selectedName: selected?.ds_orientacao,
    displayName: 'Orientações',
    toggleView,
  });

  const currentSelectedRowIndex = data?.findIndex((item) => item?.id === selected?.id);

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params, props.relation?.id]);

  React.useEffect(() => {
    if (!selected) {
      setSelected(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function load() {
    setLoading(true);
    try {
      const items = await Orientation_Api.list(
        { q: search, page: page + 1, per_page: pageSize, ...params },
        props.relation
      );

      setData(items.data);
      setTotal(items.pagination.total);
      setTotalPages(items.pagination.total_pages);
      selectRow<IDataOrientation>({
        data: items.data,
        selected,
        currentSelectedRowIndex,
        rowToSelect,
        setSelected,
      });
    } catch (error: IDataOrientationErrors | unknown) {
      if (error) {
        const err = error as IDataOrientationErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  const processRowUpdate = async (newRow, oldRow) => {
    if (rowStopReason === 'enterKeyDown' || onSaveRow) {
      const response = await Orientation_Api.update(newRow);
      load();
      setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
      setOnSaveRow(null);
      return response;
    }
    setOnSaveRow(null);
    return oldRow;
  };

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  function setDeleteModal() {
    setAlertModal({
      open: true,
      title: 'Remover orientação',
      message: (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} a orientação{' '}
          <strong>
            {selected?.id} -{' '}
            {selected && selected.ds_orientacao.length > 20
              ? `${selected?.ds_orientacao.substring(0, 20)}...`
              : selected?.ds_orientacao}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }

  async function handleDelete() {
    try {
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (selected) await Orientation_Api.delete(selected, props.relation);
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataOrientationErrors | unknown) {
      if (error) {
        const err = error as IDataOrientationErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`,
            message: err.errors.message,
            onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
            onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          });
      }
    }
  }

  function handleUpload(orientation: IDataOrientation) {
    setSelected(orientation);
  }
  async function loadAllGridRows() {
    try {
      setLoadingAllGridRows(true);
      const items = await Orientation_Api.list(
        { q: search, page: 1, per_page: total, ...params },
        props.relation
      );
      items.data.forEach((item) => {
        item['created_at'] = formatDateBR(item['created_at']);
        item['updated_at'] = formatDateBR(item['updated_at']);
      });
      const fields = columns.map((item) => item.field);
      const result: SetStateAction<any[][]> = [
        fields,
        ...items.data.map((obj) => fields.map((key) => obj[key])),
      ];
      const filteredResult = filterObjectColumns(result);
      filteredResult[0] = header;
      setAllPageRows(filteredResult);
      setLoadingAllGridRows(false);
      csvLink?.current?.link?.click();
    } catch (error: IDataOrientationErrors | unknown) {
      setLoadingAllGridRows(false);
      if (error) {
        const err = error as IDataOrientationErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    }
  }

  function onFilter(filters: IParamsQs) {
    setPage(0);
    setSelected(undefined);
    setRowToSelect(null);
    setParams({ ...filters, page: 0 });
  }

  const ToolbarComponent = (props) => (
    <Toolbar
      data={data}
      rowSelection={selected}
      options={{
        total: {
          value: total,
          total_pages: totalPages,
        },
        cancel: {
          onClick: () => setSelected(undefined),
        },
        view: {
          state: isGridView ? 'grid' : 'form',
        },
        filter: {
          columns,
          onFilter: onFilter,
        },
        page: page + 1,
        isLoadingAllGridRows: isLoadingAllGridRows,
        isLoadingGrid: loading,
        data: data,
        selectedRow: selected,
      }}
      onAddClick={() => {
        toggleView(false);
        setSelected(undefined);
      }}
      onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
      onClickView={() => toggleView(!isGridView)}
      onClickRefresh={() => load()}
      onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
      onClickAttach={() => toggleAttachModal(true)}
      searchValue={search}
      onSearchChange={(value) => setSearch(value)}
      onResetForm={() => handleResetForm('orientationForm')}
      onSaveRow={() => setOnSaveRow('save')}
      exportGridData={() => loadAllGridRows()}
      navigate={(value) => {
        setSelected(value);
      }}
      navigateToPage={(page, rowToSelect = 'first') => {
        setRowToSelect(rowToSelect);
        setPage(page - 1);
      }}
      {...props}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3}>
          {!isGridView && <ToolbarComponent />}
          {isGridView && (
            <TableMUI
              initialState={{
                columns: {
                  columnVisibilityModel: hiddenColumns,
                },
              }}
              onCellDoubleClick={(event) => {
                if (event.field === 'id') {
                  toggleView(false);
                }
              }}
              columns={columns}
              rows={data}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => {
                setPage(newPage);
                setRowToSelect(null);
              }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={(model) => {
                handleOnSortModelChange(model, setParams, setRowToSelect);
              }}
              autoHeight
              onRowEditStop={(params) => {
                setRowStopReason(params.reason);
              }}
              processRowUpdate={processRowUpdate}
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                const row = data.filter((item) => item.id === newSelection[0]);
                setSelected(row[0]);
              }}
              selectionModel={[selected?.id as number]}
              customToolbar={() => (
                <ToolbarComponent hasExportButton isGridRef fileName="UMAMI _ ORIENTAÇÕES" />
              )}
            />
          )}
          <CSVLink
            data={allPageRows}
            target="_blank"
            ref={csvLink}
            filename="Orientações"
            separator=";"
          />
          {!isGridView && (
            <OrientationFormContainer
              uuid={uuid}
              setSelected={(data) => setSelected(data)}
              data={selected}
              relation={props.relation}
              onSubmit={() => load()}
              cancel={() => toggleView(!isGridView)}
              onClickAttach={() => toggleAttachModal(true)}
            />
          )}
        </Grid>
        {/* <CreateOrientationModal
          open={isModalOpen}
          relation={props.relation}
          onClose={() => toggleModal(false)}
          onSubmit={() => load()}
        /> */}
        {selected && (
          <CreateAttachment
            data={selected}
            open={openAttach}
            onClose={() => toggleAttachModal(false)}
            onUpload={handleUpload}
          />
        )}
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayOrientation;
