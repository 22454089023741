import React from 'react';
import FormContainer from 'app/components/Containers/FormContainer';
import FormGroupUser from 'app/components/Forms/GroupUser';
import FormPermissions from 'app/components/Forms/Permissions';
import { IDataRelation } from 'app/types/data/IData';
import { GroupUserRelations, IDataGroupUser } from 'app/types/data/IDataGroupUser';

declare interface IGroupUserFormContainer {
  data?: IDataGroupUser;
  relation?: IDataRelation<GroupUserRelations>;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
}

const GroupUserFormContainer = (props: IGroupUserFormContainer) => {
  const [data, setData] = React.useState<IDataGroupUser | undefined>(props.data);
  return (
    <FormContainer
      mainForm={
        <FormGroupUser
          setSelected={(data) => props.setSelected(data)}
          data={data}
          relation={props.relation}
          onChangeRelation={(selectedData) => setData(selectedData)}
          type="update"
          onSubmit={props.onSubmit}
          onCancel={props.cancel}
        />
      }
      tabs={data && [{ title: 'Permissões', component: <FormPermissions role={data} /> }]}
    />
  );
};

export default GroupUserFormContainer;
