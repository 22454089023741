import React from 'react';
import Comment_Api from 'app/api/Comment_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import CommentForm from 'app/components/Forms/Comment';
import { IDataComment, CommentRelations } from 'app/types/data/IDataComment';
import { IDataRelation } from 'app/types/data/IData';
import CommentContext from 'app/contexts/CommentContext';

declare interface ICommentFormContainer {
  data?: IDataComment;
  relation?: IDataRelation<CommentRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
}

const CommentFormContainer = (props: ICommentFormContainer) => {
  const [data, setData] = React.useState<IDataComment | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <CommentContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        mainForm={
          <CommentForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        tabs={
          !data?.id
            ? undefined
            : [
              {
                title: 'Anexos',
                component: (
                  <GridAttachments
                    relationData={data}
                    apiModel={Comment_Api}
                    onClickAttach={props.onClickAttach}
                  />
                ),
              },
            ]
        }
      />
    </CommentContext.Provider>
  );
};

export default CommentFormContainer;
