import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, styled, TextField, Typography } from '@mui/material';
import Comment_Api from 'app/api/Comment_Api';
import AlertMessage from 'app/components/Alert';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataComment, IDataCommentErrors } from 'app/types/data/IDataComment';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CommentSchema } from '../@Schemas/CommentSchema';
import { IFormComment } from '../@Types/IFormComment';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataComment = {
  id: null,
  body: '',
  author_id: null,
  author: null,
};

const UPDATE_MSG = getMessage('Comentário', 'update');
const CREATE_MSG = getMessage('Comentário', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const CommentForm = (props: IFormComment) => {
  const { resetFormList } = useUtilsContext();

  const [data, setData] = React.useState<IDataComment>(props.data || initialValues);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  useEffect(() => {
    setData((props.data as IDataComment) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.CommentForm]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataComment>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(CommentSchema),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataComment) {
    setLoading(true);
    try {
      var newData: IDataComment;
      if (data.id) {
        newData = await Comment_Api.update(data, props.relation);
        if (props?.onSave) props.onSave();
      } else {
        newData = await Comment_Api.create(data, props.relation);
        if (props.onSubmit) props.onSubmit();
        if (props?.onSave) props.onSave();
      }
      setData(newData);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataCommentErrors | unknown) {
      if (error) {
        const err = error as IDataCommentErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <FormBox id="formik-Comment" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="body"
              control={control}
              defaultValue={data.body}
              render={({ field: { onChange } }) => (
                <TextField
                  id="body"
                  label="Mensagem*"
                  type="text"
                  value={data.body}
                  autoComplete={'off'}
                  fullWidth
                  inputProps={{
                    maxLength: 4000,
                  }}
                  variant="outlined"
                  size="small"
                  multiline
                  rows={3}
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.body?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, body: e.target.value });
                    setValue('body', e.target.value);
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          {!data.id && (
            <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
              Salvar
            </LoadingButton>
          )}
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default CommentForm;
