import FormContainer from 'app/components/Containers/FormContainer';
import FormSegmentationTypes from 'app/components/Forms/SegmentationTypes';
import { IDataRelation } from 'app/types/data/IData';
import {
  IDataSegmentationTypes,
  SegmentationTypesRelations,
} from 'app/types/data/IDataSegmentationTypes';
import React from 'react';

declare interface ISegmentationTypesFormContainer {
  data?: IDataSegmentationTypes;
  relation?: IDataRelation<SegmentationTypesRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
}

const SegmentationTypesFormContainer = (props: ISegmentationTypesFormContainer) => {
  const [data, setData] = React.useState<IDataSegmentationTypes | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        mainForm={
          <FormSegmentationTypes
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        /* tabs={
        data && [
          {
            title: 'Anexos',
            component: (
              <GridAttachments
                relationData={data}
                apiModel={SegmentationTypes_Api}
                onClickAttach={props.onClickAttach}
              />
            ),
          },
        ]
      } */
      />
    </>
  );
};

export default SegmentationTypesFormContainer;
