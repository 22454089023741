import { Grid } from '@mui/material';
import Attributes_Api from 'app/api/Attributes_Api';
import CategoriesAttribute_Api from 'app/api/CategoriesAttribute_Api';
import Categories_Api from 'app/api/Categories_Api';
import PartnerCategories_Api from 'app/api/PartnerCategories_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import CsvExcelModalImport from 'app/components/Modal/CsvExcelModalImport';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import ProjectContext from 'app/contexts/ProjectContext';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import { AttributeRelations, IDataAttribute } from 'app/types/data/IDataAttribute';
import { IDataCategoryErrors } from 'app/types/data/IDataCategory';
import {
  CategoryAttributeRelations,
  IDataCategoryAttribute,
  IDataCategoryAttributeErrors,
} from 'app/types/data/IDataCategoryAttribute';
import { IDataPartner } from 'app/types/data/IDataPartner';
import {
  IDataPartnerCategory,
  IDataPartnerCategoryErrors,
  PartnerCategoryRelations,
} from 'app/types/data/IDataPartnerCategory';
import FixedSelectOptions from 'app/utils/FixedSelectOptions';
import { getMessage } from 'app/utils/messages';
import AttributeFormContainer from 'app/views/curated/attribute/components/AttributeFormContainer';
import columns from 'app/views/curated/category/components/DisplayCategories/Columns/GridColumns';
import TreeViewCategoriesAttribute from 'app/views/curated/category_attribute/components/CategoriesAttributeTreeView';
import React, { Fragment } from 'react';
import CategoryAttributeFormContainer from '../CategoryAttributeFormContainer';
import hiddenColumns from './Columns/hiddenColumns';
import CategorieContext from 'app/contexts/CategorieContext';
import handleOnSortModelChange from 'app/utils/handleOnSortModelChange';
import selectRow from 'app/utils/selectRow';

//const header = columns.map((item) => item.field);

const UPDATE_MSG = getMessage('Categoria', 'update', 'a');
const DELETE_MSG = getMessage('Categoria', 'delete', 'a');

const initialValues: IDataCategoryAttribute | IDataPartnerCategory = {
  id: null,
  parceiro_id: null,
  categoria_id: null,
  nm_categoria: '',
  parent_id: null,
  nivel: '',
  classificacao: '',
  parent: null,
  select_option: { value: '', label: '' },
};

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplayCategoryAttribute {
  view?: 'tree' | 'grid';
  partner?: IDataPartner;
  relation?: IDataRelation<CategoryAttributeRelations | PartnerCategoryRelations>;
  displayName: string;
}

const initialProductImportData = {
  data: null,
  loading: false,
};

const DisplayCategoriesAttribute = (props: IDisplayCategoryAttribute) => {
  const { handleResetForm } = useUtilsContext();
  const ProjectCtx = React.useContext(ProjectContext);

  const [isGridView, toggleView] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [dataGrid, setDataGrid] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<
    IDataCategoryAttribute | IDataPartnerCategory | undefined
  >(undefined);
  const [created, setCreated] = React.useState<
    IDataCategoryAttribute | IDataPartnerCategory | undefined
  >(undefined);
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [rowStopReason, setRowStopReason] = React.useState<string>();
  const [onSaveRow, setOnSaveRow] = React.useState<string | null>(null);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const [importProductData, setImportProductData] = React.useState(initialProductImportData);
  const [productImportModal, setProductImportModal] = React.useState<boolean>(false);
  const [requestLoading, setRequestLoading] = React.useState({ duplicateCategorie: false });
  const [parentFromSelected, setParentFromSelected] = React.useState<any | null>(null);

  const { uuid } = useTrailHistory({
    selected,
    selectedName: selected?.nm_categoria,
    displayName: props?.displayName || 'Atributos',
    toggleView,
  });

  const currentSelectedRowIndex = data?.findIndex((item) => item?.id === selected?.id);

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    if (isGridView) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params, isGridView]);

  React.useEffect(() => {
    if (isGridView) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation?.id]);

  React.useEffect(() => {
    const parentCode = dataGrid.find((cat) => cat.code_id === selected?.parent_code_id);
    setParentFromSelected(parentCode);
  }, [selected, dataGrid]);
  async function load() {
    setLoading(true);
    try {

      var CategoriesAttribute;
      if (props.partner)
        CategoriesAttribute = await PartnerCategories_Api.list(
          {
            q: search,
            page: props.view === 'grid' ? page + 1 : 1,
            per_page: props.view === 'grid' ? pageSize : 999,
            parceiro_id: props.partner.id as number,
            projeto_id: ProjectCtx ? (ProjectCtx.id as number) : null,
            is_null: !ProjectCtx ? 'projeto_id' : undefined,
          },
          props.relation as IDataRelation<PartnerCategoryRelations>
        );
      else
        CategoriesAttribute = await CategoriesAttribute_Api.list(
          {
            q: search,
            page: 1,
            projeto_id: ProjectCtx ? (ProjectCtx.id as number) : null,
            is_null: !ProjectCtx ? 'projeto_id' : undefined,
            with_attributes: props.view === 'tree' ? '1' : '0',
            ...params,
          },
          props.relation
        );
      setDataGrid(CategoriesAttribute.data);
      const filteredData = CategoriesAttribute.data.map((item, index) => {
        return {
          id: item.code_id,
          parent: item.parent_code_id || 0,
          text: item.name,
          droppable: true,
          data: item,
        };
      });
      setData(filteredData);
      /*       if (selected) {
        setSelected((prev) => prev);
      } */
      setTotal(CategoriesAttribute.pagination.total);
      setTotalPages(CategoriesAttribute.pagination.total_pages);
      selectRow<IDataCategoryAttribute>({
        data: CategoriesAttribute.data,
        selected,
        currentSelectedRowIndex,
        rowToSelect,
        setSelected,
      });
      // setLoading(false);
    } catch (error: IDataCategoryAttributeErrors | IDataPartnerCategoryErrors | unknown) {
      setLoading(false);
      if (error) {
        const err = error as IDataCategoryAttributeErrors | IDataPartnerCategoryErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  async function doExport() {
    setLoading(true);
    try {
      await Categories_Api.export(
        {
          q: search,
          page: 1,
          per_page: total,
          projeto_id: ProjectCtx ? (ProjectCtx.id as number) : null,
          is_null: !ProjectCtx ? 'projeto_id' : undefined,
          ...params,
        },
        props.relation
      );
    } catch (error: IDataCategoryErrors | unknown) {
      if (error) {
        const err = error as IDataCategoryErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdate(node, newValue: string) {
    if (selected) {
      setLoading(true);
      try {
        if (props.partner) {
          await PartnerCategories_Api.update(
            { ...(selected as IDataPartnerCategory), nm_categoria: newValue },
            props.view !== 'grid'
              ? undefined
              : (props.relation as IDataRelation<PartnerCategoryRelations>)
          );
        } else if (node.data.is_attribute) {
          await Attributes_Api.update({
            ...(selected as unknown as IDataAttribute),
            nm_atributo: newValue,
          });
        } else {
          await CategoriesAttribute_Api.update(
            { ...(selected as IDataCategoryAttribute), nm_categoria: newValue },
            props.view !== 'grid' ? undefined : props.relation
          );
        }

        load();
        setToast({
          open: true,
          message: UPDATE_MSG,
          severity: initialToast.severity,
        });
      } catch (error: IDataCategoryAttributeErrors | IDataPartnerCategoryErrors | unknown) {
        const err = error as IDataCategoryAttributeErrors | IDataPartnerCategoryErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`,
            message: err.errors.message,
            onClose: () => setAlertModal({ ...alertModal, open: false }),
            onConfirm: () => setAlertModal({ ...alertModal, open: false }),
          });
      } finally {
        setLoading(false);
      }
    }
  }

  const processRowUpdate = async (newRow, oldRow) => {
    if (rowStopReason === 'enterKeyDown' || onSaveRow) {
      var response;
      if (props.partner) response = await PartnerCategories_Api.update(newRow);
      else response = await CategoriesAttribute_Api.update(newRow);

      load();
      setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
      setOnSaveRow(null);
      return response;
    }
    setOnSaveRow(null);
    return oldRow;
  };

  function handleCloseToast() {
    setToast({ open: false, ...initialToast });
  }

  async function handleDelete() {
    try {
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (props.partner) {
        if (selected)
          await PartnerCategories_Api.delete(
            selected as IDataPartnerCategory,
            props.relation as IDataRelation<PartnerCategoryRelations>
          );
      } else {
        if (selected) await CategoriesAttribute_Api.delete(selected, props.relation);
      }
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataCategoryAttributeErrors | unknown) {
      const err = error as IDataCategoryAttributeErrors;
      if (err.errors.status && err.errors.message)
        setAlertModal({
          open: true,
          title: `${err.errors.status} - Não foi possível prosseguir`,
          message: err.errors.message,
          onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
        });
    }
  }

  function setDeleteModal() {
    setAlertModal({
      open: true,
      title: `${props.relation ? 'Retirar' : 'Remover'} categoria`,
      message: (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} a categoria{' '}
          <strong>
            {selected?.id} - {selected?.nm_categoria}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }

  function handleClickView() {
    if (!isGridView) setSelected(undefined);
    toggleView(!isGridView);
  }

  async function onImportProducts(file) {
    setImportProductData(initialProductImportData);
    try {
      setImportProductData((prev) => ({ ...prev, loading: true }));
      const resp = await Categories_Api.importProducts(file, props.relation);
      setImportProductData((prev) => ({ ...prev, data: resp, loading: false }));
      setToast({
        open: true,
        message: 'Categorias importadas',
        severity: 'success',
      });
      load();
      //setProductImportModal(false);
    } catch (error) {
      setToast({
        open: true,
        message: 'Não foi possível importar o arquivo. Verifique Se a Planilha de Produtos Está Correta',
        severity: 'error',
      });
      setImportProductData((prev) => ({ ...prev, data: null, loading: false }));
    }
  }

  function onFilter(filters: IParamsQs) {
    setPage(0);
    setSelected(undefined);
    setRowToSelect(null);
    setParams({ ...filters, page: 0 });
  }

  async function duplicateRow() {
    try {
      setRequestLoading((prev) => ({ ...prev, duplicateCategorie: true }));
      await CategoriesAttribute_Api.duplicate(selected?.id as number, props.relation);
      load();
      setToast({
        open: true,
        message: 'Categoria duplicada com sucesso',
        severity: initialToast.severity,
      });
      setRequestLoading((prev) => ({ ...prev, duplicateCategorie: false }));
    } catch (error) {
      setToast({
        open: true,
        message: 'Não foi possível duplicar a categoria. Tente novamente mais tarde',
        severity: 'error',
      });
      setRequestLoading((prev) => ({ ...prev, duplicateCategorie: false }));
    }
  }

  const ToolbarComponent = (props) => (
    <Toolbar
      options={{

        view: {
          state: isGridView ? 'grid' : 'form',
          //disabled: props.view === 'grid' ? false : !Boolean(selected),
        },
        cancel: {
          onClick: props.view !== 'grid' ? () => setSelected(undefined) : undefined,
        },
        total: {
          value: total,
          total_pages: totalPages,
        },
        filter: {
          columns,
          onFilter: onFilter,
        },
        page: page + 1,
        //isLoadingAllGridRows: isLoadingAllGridRows,
        //isLoadingGrid: loading,
        data: data,
        selectedRow: selected,
        canDeleteAttribute: selected?.is_attribute,
        requestLoading: requestLoading,
      }}
      onAddClick={() => {
        toggleView(false);
        setCreated({ ...initialValues, parent_id: selected?.id || null, parent: selected });
        //setCreated(undefined);
        setSelected(undefined);
      }}
      onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
      onClickView={handleClickView}
      onClickRefresh={() => load()}
      onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
      onSearchChange={(value) => {
        setSearch(value);
      }}
      searchValue={search}
      onResetForm={() => handleResetForm('categoriesForm')}
      onSaveRow={() => setOnSaveRow('save')}
      onFileImport={() => setProductImportModal(true)}
      exportGridData={() => doExport()}
      duplicateRow={() => duplicateRow()}
      navigate={(value) => {
        setSelected(value);
      }}
      navigateToPage={(page, rowToSelect = 'first') => {
        setRowToSelect(rowToSelect);
        setPage(page - 1);
      }}
      {...props}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3}>
          {!isGridView && <ToolbarComponent />}
          {isGridView && props.view === 'grid' && (
            <TableMUI
              initialState={{
                columns: {
                  columnVisibilityModel: hiddenColumns,
                },
              }}
              onCellDoubleClick={(event) => {
                if (event.field === 'id') {
                  toggleView(false);
                }
              }}
              columns={columns}
              rows={dataGrid}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => {
                setPage(newPage);
                setRowToSelect(null);
              }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={(model) => {
                handleOnSortModelChange(model, setParams, setRowToSelect);
              }}
              autoHeight
              onRowEditStop={(params) => {
                setRowStopReason(params.reason);
              }}
              processRowUpdate={processRowUpdate}
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                const row = data.filter((item) => item.id === newSelection[0]);
                if (!!row.length) {
                  setSelected(row[0]?.data || row[0]);
                }
              }}
              selectionModel={[selected?.id as number]}
              customToolbar={() => (
                <ToolbarComponent hasExportButton isGridRef fileName="UMAMI _ CATEGORIAS" />
              )}
            />
          )}
          {isGridView && props.view !== 'grid' && (
            <>
              <ToolbarComponent hasExportButton />
              <TreeViewCategoriesAttribute
                loading={loading}
                nodeSelected={
                  selected
                    ? {
                      id: selected.id as number,
                      text: selected.nm_categoria,
                      parent: selected.parent_id as number,
                      data: selected,
                    }
                    : undefined
                }
                clearSelected={!Boolean(selected)}
                onClickNode={(node) => setSelected(node.data)}
                data={data}
                onDrop={() => load()}
                onUpdate={(node, newValue) => handleUpdate(node, newValue)}
              />
            </>
          )}
          {!isGridView && !selected?.is_attribute && (
            <CategoryAttributeFormContainer
              uuid={uuid}
              setSelected={(data) => setSelected(data)}
              relation={props.relation}
              data={selected || created}
              selected={selected}
              onSubmit={() => {
                load();
                toggleView(!isGridView);
              }}
              partner={props.partner}
              cancel={() => toggleView(!isGridView)}
            />
          )}
          {!isGridView && selected?.is_attribute && (
            <CategorieContext.Provider value={parentFromSelected}>
              <AttributeFormContainer
                uuid={uuid}
                setSelected={(data) => setSelected(data)}
                relation={props.relation as unknown as IDataRelation<AttributeRelations>}
                data={
                  ({
                    ...(selected as unknown as IDataAttribute),
                    label:
                      FixedSelectOptions.Atrribute.label[
                      (selected as unknown as IDataAttribute).label as number
                      ],
                    relative_to:
                      FixedSelectOptions.Atrribute.relative_to[
                      (selected as unknown as IDataAttribute).relative_to as string
                      ],
                  } as unknown as IDataAttribute) || created
                }
                onSubmit={() => load()}
                cancel={() => toggleView(!isGridView)}
                partner={props.partner}
                local="categoria"
              />
            </CategorieContext.Provider>
          )}
          <CsvExcelModalImport
            data={importProductData?.data}
            fileModel="/api/storage/_modelos_importacao/Layout_Importação_Categorias_UMAMI.zip"
            onSubmit={(files) => onImportProducts(files[0])}
            loading={importProductData?.loading}
            title="Importar Categorias"
            open={productImportModal}
            onClose={() => {
              setProductImportModal(false);
              setImportProductData(initialProductImportData);
            }}
            onCancel={() => {
              setProductImportModal(false);
              setImportProductData(initialProductImportData);
            }}
          />
        </Grid>
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayCategoriesAttribute;