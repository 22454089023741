import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Breadcrumbs, Link, Paper, Stack, Tab, Typography, styled } from '@mui/material';
import { useBreadcrumb } from 'app/contexts/BreadcrumbContext';
import React, { ReactElement } from 'react';

const FormBox = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4),
}));

const ContentTabList = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  boxShadow: `inset 1px 1px 16px rgba(0,0,0,0.05)`,
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 0,
}));

const ContentTabs = styled(Box)(({ theme }) => ({
  width: '100%',
  //overflow: 'hidden',
  position: 'relative',
  boxShadow: `inset 1px 1px 16px rgba(0,0,0,0.1)`,
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 1, 1),
  marginTop: '0px!important',
}));

declare interface ITabsComponent {
  title: string;
  component: ReactElement;
}

declare interface IFormContainer {
  mainForm: ReactElement;
  tabs?: ITabsComponent[];
  setSelected?: (data: unknown) => void;
  uuid?: string;
}

const FormContainer = (props: IFormContainer) => {
  const [value, setValue] = React.useState('0');
  const { breadCrumb } = useBreadcrumb();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const cuttedBreadcrumb = (num: number = 1, uuid: string) =>
    breadCrumb.slice(0, breadCrumb.findIndex((obj) => obj.uuid === uuid) + num);

  return (
    <FormBox key={props?.uuid}>
      <Stack spacing={2}>
        {props.mainForm}

        {props.tabs && (
          <>
            <Stack spacing={2}>
              <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                {cuttedBreadcrumb(2, props?.uuid as string)?.map((item, index) => (
                  <Box display="flex" alignItems="center" key={index}>
                    {index === cuttedBreadcrumb(2, props?.uuid as string).length - 1 ? (
                      <Typography sx={{ cursor: 'default' }} color="inherit">
                        {item.name}
                      </Typography>
                    ) : (
                      <Link
                        sx={{ cursor: 'pointer' }}
                        underline="hover"
                        onClick={() => {
                          item.toggleView();
                        }}
                      >
                        {item.name}
                      </Link>
                    )}
                    {index + 1 === breadCrumb.length ? null : (
                      <ArrowForwardIosIcon sx={{ fontSize: '12px', margin: '0 4px' }} />
                    )}
                    {index === cuttedBreadcrumb(2, props?.uuid as string).length - 1 &&
                      breadCrumb.length > 1 ? null : item.uuid === props?.uuid ||
                        breadCrumb.length <= 1 ? (
                      <Typography sx={{ cursor: 'default' }} color="inherit">
                        {item.selected_name}
                      </Typography>
                    ) : (
                      <Link
                        sx={{ cursor: 'pointer' }}
                        underline="hover"
                        onClick={() => {
                          const tillNextTab = cuttedBreadcrumb(2, item.uuid);
                          const nextTab = tillNextTab[tillNextTab.length - 1];
                          nextTab.toggleView();
                        }}
                      >
                        {item.selected_name}
                      </Link>
                    )}
                  </Box>
                ))}
              </Breadcrumbs>
            </Stack>
            <TabContext value={value}>
              <ContentTabList>
                <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto">
                  {props.tabs.map((tab, key) => (
                    <Tab key={key} label={tab.title} value={key.toString()} />
                  ))}
                </TabList>
              </ContentTabList>
              <ContentTabs>
                {props.tabs.map((tab, key) => (
                  <TabPanel sx={{ pr: 0, pb: 1 }} key={key} value={key.toString()}>
                    {tab.component}
                  </TabPanel>
                ))}
              </ContentTabs>
            </TabContext>
          </>
        )}
      </Stack>
    </FormBox>
  );
};

export default FormContainer;
