import FormContainer from 'app/components/Containers/FormContainer';
import CategoryAttributeForm from 'app/components/Forms/Categories';
import CategorieContext from 'app/contexts/CategorieContext';
import { IDataRelation } from 'app/types/data/IData';
import {
  CategoryAttributeRelations,
  IDataCategoryAttribute,
} from 'app/types/data/IDataCategoryAttribute';
import { IDataPartner } from 'app/types/data/IDataPartner';
import { IDataPartnerCategory } from 'app/types/data/IDataPartnerCategory';
import DisplayAttributes from 'app/views/curated/attribute/components/DisplayAttributes';
import React from 'react';

declare interface ICategoryAttributeFormContainer {
  data?: IDataCategoryAttribute | IDataPartnerCategory;
  selected?: IDataCategoryAttribute | IDataPartnerCategory;
  relation?: IDataRelation<CategoryAttributeRelations>;
  partner?: IDataPartner;
  onClickAttach?: () => void;
  onSubmit: (data?) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
}

const CategoryAttributeFormContainer = (props: ICategoryAttributeFormContainer) => {
  const [data, setData] = React.useState<IDataCategoryAttribute | IDataPartnerCategory | undefined>(
    props.data
  );
  const [selected, setSelected] = React.useState<
    IDataCategoryAttribute | IDataPartnerCategory | undefined
  >(props.data);

  React.useEffect(() => {
    setData(props.data);
    setSelected(props.selected);
  }, [props.data, props.selected]);

  return (
    <CategorieContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <CategoryAttributeForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
            partner={props.partner}
          />
        }
        tabs={
          data &&
          selected && [
            {
              title: 'Atributos',
              component: (
                <DisplayAttributes
                  relation={{ id: data.id as number, relation: 'Category' }}
                  view="grid"
                  local="categoria"
                />
              ),
            },
          ]
        }
      />
    </CategorieContext.Provider>
  );
};

export default CategoryAttributeFormContainer;
