import FormContainer from 'app/components/Containers/FormContainer';
import UnitForm from 'app/components/Forms/Unit';
import { IDataRelation } from 'app/types/data/IData';
import { IDataUnit, UnitRelations } from 'app/types/data/IDataUnit';
import DisplayOrientation from 'app/views/curated/orientation/components/DisplayOrientation';
import React from 'react';

declare interface IUnitFormContainer {
  data?: IDataUnit;
  relation?: IDataRelation<UnitRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
}

const UnitFormContainer = (props: IUnitFormContainer) => {
  const [data, setData] = React.useState<IDataUnit | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);
  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <UnitForm
            setSelected={(data) => props.setSelected(data)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        tabs={
          data && [
            {
              title: 'Orientações',
              component: (
                <DisplayOrientation relation={{ id: data.id as number, relation: 'Unit' }} />
              ),
            },
          ]
        }
      />
    </>
  );
};

export default UnitFormContainer;
