import FormContainer from 'app/components/Containers/FormContainer';
import FormReasonType from 'app/components/Forms/ReasonType';
import { IDataRelation } from 'app/types/data/IData';
import { IDataReasonType, ReasonTypeRelations } from 'app/types/data/IDataReasonType';
import React from 'react';

declare interface IReasonTypeFormContainer {
  data?: IDataReasonType;
  relation?: IDataRelation<ReasonTypeRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
}

const ReasonTypeFormContainer = (props: IReasonTypeFormContainer) => {
  const [data, setData] = React.useState<IDataReasonType | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormReasonType
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        /* tabs={
        data && [
          {
            title: 'Anexos',
            component: (
              <GridAttachments
                relationData={data}
                apiModel={ReasonType_Api}
                onClickAttach={props.onClickAttach}
              />
            ),
          },
        ]
      } */
      />
    </>
  );
};

export default ReasonTypeFormContainer;
