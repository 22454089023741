import React from 'react';

import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations } from 'app/types/data/IDataActivity';
import KanbanContainer from './components/KanbanContainer';
import { KanbanProvider } from 'app/contexts/Kanban/KanbanStepsContext';

interface ActivityKanbanProps {
  relation?: IDataRelation<ActivityRelations>;
}

const ActivityKanban = ({ relation }: ActivityKanbanProps) => {
  return (
    <KanbanProvider>
      <KanbanContainer relation={relation} />
    </KanbanProvider>
  );
};

export default ActivityKanban;
