import React, { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import UserAuth_Api from '../api/UserAuth_api';
import { IDataUser } from 'app/types/data/IDataUser';
import jwtDecode from 'jwt-decode';
import { IToken } from 'app/types/IToken';

declare interface IState {
  isAuthenticated: boolean;
  isInitialised: boolean;
  isLogingOut: boolean;
  user: IDataUser | null;
}

const initialState: IState = {
  isAuthenticated: false,
  isInitialised: false,
  isLogingOut: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const currentTime = Date.now() / 1000;
  return jwtDecode<IToken>(accessToken).exp > currentTime;
};

const setSession = (accessToken: string | null, expires?) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('expires', expires);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('expires');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        isLogingOut: false,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isLogingOut: false,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        isLogingOut: true,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isLogingOut: false,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: (email, password) => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  refresh: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email: string, password: string) => {
    const response = await UserAuth_Api.login({
      email,
      password,
    });
    const accessToken = response.access.access_token;
    const expires = response.access.expires_in;
    const user = response.user;

    setSession(accessToken, expires);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email: string, username: string, password: string) => {
    const response = await axios.post('/api/auth/register', {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    await UserAuth_Api.logout();
    setSession(null);
    dispatch({
      type: 'LOGOUT',
      payload: {
        isLogingOut: true,
      },
    });
    dispatch({
      type: 'INIT',
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const refresh = async () => {
    try {
      const accessToken = window.sessionStorage.getItem('accessToken');
      const expires = window.sessionStorage.getItem('expires');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, expires);
        const response = await UserAuth_Api.isUserValid();
        const { user } = response;

        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INIT',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.sessionStorage.getItem('accessToken');
        const expires = window.sessionStorage.getItem('expires');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, expires);
          const response = await UserAuth_Api.isUserValid();
          const { user } = response;

          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user,
            },
          });

          window.addEventListener('beforeunload', (event) => {
            logout();
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised || state.isLogingOut) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
