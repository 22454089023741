import Activity_Api from 'app/api/Activity_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import FormActivity from 'app/components/Forms/Activity';
import NotificationConfForm from 'app/components/Forms/Activity/NotificationConfForm';
import WorkflowActivityContext from 'app/contexts/WorkflowActivityContext';
import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations, IDataActivity } from 'app/types/data/IDataActivity';
import DisplayComment from 'app/views/@general/comment/components/DisplayComment';
import React from 'react';
import DisplayReasons from '../../../../curated/reasons/components/DisplayReasons';
import DisplayActivity from '../DisplayActivity';

declare interface IActivityFormContainer {
  data?: IDataActivity;
  predecessora?: IDataActivity | null;
  relation?: IDataRelation<ActivityRelations>;
  onClickAttach?: () => void;
  onSubmit?: () => void;
  cancel?: () => void;
  setSelected?: (data) => void;
  onFormSave?: () => void;
  uuid?: string;
}

const ActivityFormContainer = (props: IActivityFormContainer) => {
  const [data, setData] = React.useState<IDataActivity | undefined>(props.data);
  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <WorkflowActivityContext.Provider value={{ data, onFormSave: props?.onFormSave }}>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormActivity
            setSelected={(data) => props?.setSelected && props.setSelected(data.data)}
            data={data}
            defaultPredecessora={props.predecessora}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
            isSub={false}
          />
        }
        tabs={
          data && [
            {
              title: 'Sub-Atividades',
              component: (
                <DisplayActivity
                  displayName="Sub-Atividades"
                  relation={{ id: data?.id as number, relation: 'Activity' }}
                  view="grid"
                  type="subatividades"
                />
              ),
            },
            {
              title: 'Sucessoras',
              component: (
                <DisplayActivity
                  displayName="Sucessoras"
                  relation={{ id: data?.id as number, relation: 'Activity' }}
                  view="grid"
                  type="sucessoras"
                />
              ),
            },
            {
              title: 'Motivos',
              component: (
                <DisplayReasons relation={{ id: data.id as number, relation: 'Activity' }} />
              ),
            },
            {
              title: 'Comentários',
              component: (
                <DisplayComment relation={{ id: data.id as number, relation: 'Activity' }} />
              ),
            },
            {
              title: 'Notificações',
              component: (
                <NotificationConfForm
                  relation={{ id: data?.id as number, relation: 'Activity' }}
                  refreshGrid={props.onSubmit}
                />
              ),
            },
            {
              title: 'Anexos',
              component: (
                <GridAttachments
                  relationData={data}
                  apiModel={Activity_Api}
                  onClickAttach={props.onClickAttach}
                />
              ),
            },
          ]
        }
      />
    </WorkflowActivityContext.Provider>
  );
};

export default ActivityFormContainer;
