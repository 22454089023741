import React, { useCallback, useMemo, HTMLAttributes } from 'react';
import {
  CircularProgress,
  Divider,
  Paper,
  Stack,
  styled,
  Typography as TypographyMui,
} from '@mui/material';
import { IDataSegmentation } from 'app/types/data/IDataSegmentation';

//----------INTERFACES----------//
interface SegmentationLibraryProps {
  data: IDataSegmentation[];
  setSelected: (value: IDataSegmentation) => void;
  loadingData: boolean;
  selected?: IDataSegmentation;
  toggleView?: (value: boolean) => void;
}

interface CardProps extends HTMLAttributes<HTMLUListElement> {
  isSelected?: boolean;
  src?: string | null;
}

//-------------------------------//

//----------STYLES COMPONENTS----------//
const CardList = styled('ul')(({ theme }) => ({
  padding: 0,
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '2rem',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));
const Card = styled('li')<CardProps>(({ theme, isSelected }) => ({
  listStyle: 'none',
  '& .MuiPaper-root': {
    backgroundColor: isSelected ? '#a5c7a7' : '#fff',
    color: isSelected ? '#fff' : '#838383',
  },
  borderRadius: '5px',
  width: '250px',
}));

const Typography = styled(TypographyMui)<CardProps>(({ theme, isSelected }) => ({
  color: '#838383',
  width: 'max-content',
  cursor: 'default',
}));

const CardTitle = styled('div')(({ theme }) => ({
  fontWeight: '500',
  cursor: 'default',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

const CardImage = styled('div')<CardProps>(({ theme, src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: '100% 100%',
  width: '100%',
  objectFit: 'cover',
  height: '250px',
  aspectRatio: '4/3',
  marginBottom: '8px',
}));
//--------------------------------------//

const SegmentationLibrary: React.FC<SegmentationLibraryProps> = ({
  data,
  setSelected,
  loadingData,
  selected,
  toggleView,
}) => {
  //  Group all the items by checking their model and segment IDs.
  const groupBySegmentAndModel = useCallback(
    (arr: SegmentationLibraryProps['data']) =>
      arr.reduce<{ [key: string]: SegmentationLibraryProps['data'] }>((acc, curr) => {
        if (curr.modelo_segmentacao?.name && curr.segmento_segmentacao?.name) {
          const key = `${curr.modelo_segmentacao?.id}_${curr.segmento_segmentacao?.id} Árvores ${curr.modelo_segmentacao?.name} ${curr.segmento_segmentacao?.name}`;
          if (!acc[key]) acc[key] = [];
          acc[key].push(curr);
        }
        return acc;
      }, {}),
    []
  );

  const groupedData = useMemo(() => groupBySegmentAndModel(data), [data, groupBySegmentAndModel]);

  // Card list
  const renderedData = Object.keys(groupedData).map((key) => (
    <div key={key}>
      <Typography variant="h6" mb={2}>
        {key.split(' ').slice(1).join(' ')}
      </Typography>
      <CardList>
        {groupedData[key].map((item) => (
          <Card
            onClick={() => setSelected(item)}
            isSelected={selected && item.id === selected?.id}
            onDoubleClick={() => toggleView && toggleView(false)}
          >
            <Paper elevation={5} sx={{ padding: '8px' }}>
              <CardImage
                src={
                  item?.image?.[0]?.original_url ||
                  '/assets/images/placeholder/placeholder_umami.jpg'
                }
              ></CardImage>
              <CardTitle key={item.id}>{item.name}</CardTitle>
            </Paper>
          </Card>
        ))}
      </CardList>
      <Divider style={{ marginBottom: '4rem' }} />
    </div>
  ));

  return (
    <div style={{ position: 'relative' }}>
      {loadingData ? (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <CircularProgress />
        </Stack>
      ) : (
        renderedData
      )}
      {!loadingData && !Boolean(data.length) && (
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img src="/assets/images/no-data.jpg" alt="" width="35%" />
          <Typography variant="h5" mb={2}>
            Dados não encontrados.
          </Typography>
        </Stack>
      )}
    </div>
  );
};

export default SegmentationLibrary;
