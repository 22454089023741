import Orientation_Api from 'app/api/Orientation_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import FormOrientation from 'app/components/Forms/Orientation';
import { IDataRelation } from 'app/types/data/IData';
import { IDataOrientation, OrientationRelations } from 'app/types/data/IDataOrientation';
import React from 'react';

declare interface IOrientationFormContainer {
  data?: IDataOrientation;
  relation?: IDataRelation<OrientationRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
}

const OrientationFormContainer = (props: IOrientationFormContainer) => {
  const [data, setData] = React.useState<IDataOrientation | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormOrientation
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        tabs={
          data && [
            {
              title: 'Anexos',
              component: (
                <GridAttachments
                  relationData={data}
                  apiModel={Orientation_Api}
                  onClickAttach={props.onClickAttach}
                />
              ),
            },
          ]
        }
      />
    </>
  );
};

export default OrientationFormContainer;
