import React from 'react';
import FormContainer from 'app/components/Containers/FormContainer';
import FormUser from 'app/components/Forms/User';
import { IDataUser, UserRelations } from 'app/types/data/IDataUser';
import DisplayGroupUser from 'app/views/management/user-group/components/DisplayGroupUser';
import { IDataRelation } from 'app/types/data/IData';

declare interface IUserFormContainer {
  data?: IDataUser;
  relation?: IDataRelation<UserRelations>;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
}

const UserFormContainer = (props: IUserFormContainer) => {
  const [data, setData] = React.useState<IDataUser | undefined>(props.data);
  return (
    <FormContainer
      mainForm={
        <FormUser
          setSelected={(data) => props.setSelected(data)}
          onChangeRelation={(selectedData) => setData(selectedData)}
          relation={props.relation}
          data={props.data}
          type="update"
          onSubmit={props.onSubmit}
          onCancel={props.cancel}
        />
      }
      tabs={
        data && [
          {
            title: 'Grupos',
            component: <DisplayGroupUser relation={{ id: data.id as number, relation: 'User' }} />,
          },
        ]
      }
    />
  );
};

export default UserFormContainer;
