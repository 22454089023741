import React, { useEffect } from 'react';
import {
  Button,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Typography,
  Alert,
} from '@mui/material';
import { FileRejection, useDropzone } from 'react-dropzone';
import { LoadingButton } from '@mui/lab';
import { TranslateMessages } from 'app/components/Forms/@Messages';
var humanFormat = require('human-format');

const Dropzone = styled('section')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
}));

const DropzoneArea = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 200,
  border: `2px dashed ${theme.palette.primary.light}`,
  borderRadius: 16,
  backgroundColor: theme.palette.grey[200],
  cursor: 'pointer',
}));

const Aside = styled('aside')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ListArea = styled('div')(({ theme }) => ({
  width: '100%',
  '& ul': {
    overflowY: 'auto',
  },
}));

const ListItemFile = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  width: '100%',
}));

const ListItemFileError = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.error.contrastText,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  width: '100%',
}));

const ErrorMessage = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));

const formatTypes = {
  csv_excel: {
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx', '.csv'],
    },
    docInfo: undefined,
  },
};

declare interface IFormAttachments {
  maxSize?: number;
  loading?: boolean;
  clearFiles?: boolean;
  onSubmit?: (files: File[], links?: string[]) => void;
  onCancel?: () => void;
  goBackToInitial?: () => void;
  errors?: object;
  data: { log_file: string; imports_fail: number; imports_success: number } | null;
}

const ImportForm = (props: IFormAttachments) => {
  const MAX_SIZE = props.maxSize || 50000000;
  const [SelectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [errorFiles, setErrorFiles] = React.useState<FileRejection[]>([]);
  const [linkErrors, setLinkErrors] = React.useState<String[] | undefined>(undefined);

  useEffect(() => {
    const err = props.errors && Object.values(props.errors).flat();
    setLinkErrors(err);
  }, [props.errors]);

  const format = 'csv_excel';

  React.useEffect(() => {
    if (props.clearFiles) setSelectedFiles([]);
  }, [props.clearFiles]);

  function fileValidator(file: File) {
    if (file.size > MAX_SIZE) {
      return {
        code: 'size-too-large',
        message: `Tamanho do arquivo excede o máximo de ${humanFormat(MAX_SIZE)}.`,
      };
    }

    return null;
  }

  const onDrop = React.useCallback(
    (acceptedFiles: File[], fileRejections) => {
      setSelectedFiles(acceptedFiles);
      setErrorFiles(fileRejections);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [SelectedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: formatTypes[format].accept,
    maxFiles: 1,
    multiple: false,
    validator: fileValidator,
  });

  const files = SelectedFiles.map((file: File) => (
    <ListItemFile
      key={file.name}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file)}>
          <Icon>delete</Icon>
        </IconButton>
      }
    >
      <ListItemText primary={file.name} secondary={humanFormat(file.size)} />
    </ListItemFile>
  ));

  const errors = errorFiles.map((error: FileRejection) => (
    <ListItemFileError key={error.file.name}>
      <ListItemText
        primary={error.file.name}
        secondary={<ErrorMessage>{TranslateMessages(error.errors[0].code)}</ErrorMessage>}
      />
    </ListItemFileError>
  ));

  function handleDelete(file: File) {
    const newFiles = [...SelectedFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setSelectedFiles(newFiles);
  }

  function handleSubmit() {
    if (props.onSubmit) {
      props.onSubmit(SelectedFiles);
    }
  }

  return (
    <>
      <Dropzone>
        <DropzoneArea {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Stack>
            <Typography variant="button">Solte seu arquivo aqui ou clique para escolher</Typography>
            {formatTypes[format].docInfo && (
              <Typography variant="caption">{formatTypes[format].docInfo} </Typography>
            )}
            {props?.maxSize && (
              <Typography variant="caption">
                Tamanho Máximo por arquivo: {humanFormat(MAX_SIZE)}.{' '}
              </Typography>
            )}
          </Stack>
        </DropzoneArea>
        {files.length > 0 && (
          <Aside>
            <Typography variant="h6" component="h4">
              Arquivos
            </Typography>
            <ListArea sx={{ '& ul': { maxHeight: errors.length ? 120 : 250 } }}>
              <List dense>{files}</List>
            </ListArea>
          </Aside>
        )}
        {errors.length > 0 && (
          <Aside>
            <Typography variant="h6" component="h4">
              Arquivos Inválidos
            </Typography>
            <ListArea sx={{ '& ul': { maxHeight: errors.length ? 120 : 250 } }}>
              <List dense>{errors}</List>
            </ListArea>
          </Aside>
        )}
      </Dropzone>
      {props.data && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert>
            Importação finalizada.{' '}
            {props?.data?.log_file && (
              <>
                Clique{' '}
                <a
                  style={{ color: '#007dc7' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  href={props?.data?.log_file}
                >
                  aqui
                </a>{' '}
                para baixar o arquivo com o resultado da importação
              </>
            )}
          </Alert>
        </Stack>
      )}
      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.goBackToInitial || props.onCancel} color="primary">
          {props.goBackToInitial ? 'Voltar' : 'Cancelar'}
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          disabled={!Boolean(SelectedFiles.length) || props.loading || Boolean(linkErrors?.length)}
          type="submit"
          color="primary"
          loading={props.loading}
          variant="contained"
        >
          Enviar
        </LoadingButton>
      </Grid>
    </>
  );
};

export default ImportForm;
