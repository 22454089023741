import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import { IDataIntegrator } from 'app/types/data/IDataIntegrator';
import {
  IDataProduct,
  IDataProductErrors,
  IHookFormProduct,
  IProductFilter,
  IProductPagination,
  ProductExtraOptions,
  ProductFields,
  ProductRelations,
} from 'app/types/data/IDataProduct';
import FixedSelectOptions from 'app/utils/FixedSelectOptions';
import { AxiosError } from 'axios';
import { default as QueryString, default as qs } from 'qs';
import Model_Api from './Model_Api';
import Api from './api';
import { IDataIDCurate } from 'app/types/data/IDataStore';

export default class Product_Api extends Model_Api {
  static self = new Product_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataProductErrors {
    const resp: IDataProductErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormProduct[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as ProductFields;
      const item: IHookFormProduct = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IProductFilter,
    relation?: IDataRelation<ProductRelations>
  ): Promise<IProductPagination> {
    try {
      if (relation) {
        const resp = (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/produto`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp?.data?.forEach((item) => {
          item['integration_situation'] =
            FixedSelectOptions.Product.integration_situation[item.integration_situation] || null;
          item['integration_status'] =
            FixedSelectOptions.Product.integration_status[item.integration_status] || null;
        });
        return resp as IProductPagination;
      } else {
        const resp = (
          await Api.get(Endpoints.Product.main, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp?.data?.forEach((item) => {
          item['integration_situation'] =
            FixedSelectOptions.Product.integration_situation[item.integration_situation] || null;
          item['integration_status'] =
            FixedSelectOptions.Product.integration_status[item.integration_status] || null;
        });
        return resp as IProductPagination;
      }
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataProduct) {
    try {
      return (await Api.get(`${Endpoints.Product.main}/${data.id}`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Products', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataProduct,
    relation?: IDataRelation<ProductRelations>,
    options?: ProductExtraOptions[]
  ): Promise<IDataProduct> {
    try {
      var resp: IDataProduct | null = null;

      if (relation)
        resp = (
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}${Endpoints.Product.main}`,
            { ...data, to_keep: options, vitrine_virtual: data?.vitrine_virtual ? 1 : 0 }
          )
        ).data.produto as IDataProduct;
      else
        resp = (
          await Api.post(Endpoints.Product.main, {
            ...data,
            to_keep: options,
            vitrine_virtual: data?.vitrine_virtual ? 1 : 0,
          })
        ).data.produto as IDataProduct;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Products', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataProduct,
    relation?: IDataRelation<ProductRelations>
  ): Promise<IDataProduct> {
    try {
      var resp: IDataProduct;
      if (relation) {
        resp = (
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}${Endpoints.Product.main}`,
            { ...data, vitrine_virtual: data?.vitrine_virtual ? 1 : 0 }
          )
        ).data.produto as IDataProduct;
      } else {
        resp = (
          await Api.put(`${Endpoints.Product.main}/${data.id}`, {
            ...data,
            vitrine_virtual: data?.vitrine_virtual ? 1 : 0,
          })
        ).data.produto;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Products', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataProduct,
    relation?: IDataRelation<ProductRelations>
  ): Promise<IDataProduct> {
    try {
      var resp: IDataProduct;

      if (relation)
        resp = (
          await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/produto/${data.id}`)
        ).data.produto;
      else resp = (await Api.delete(`${Endpoints.Product.main}/${data.id}`)).data.produto;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Products', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    produto?: IDataProduct
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          produto
            ? `${Endpoints.Product.main}/${produto.id}/media`
            : `/media${Endpoints.Product.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static async attach(produto: IDataProduct, files: File[], links?: String[], as_copy?: number) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });
      links?.forEach((link, index) => {
        formData.append(`link[${index}]`, link + '');
      });

      return (
        await Api.post(`${Endpoints.Product.main}/${produto.id}/media/attach`, formData, {
          params: { as_copy },
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Products', handleErros);
      throw handleErros;
    }
  }

  static async attachMetaImage(
    produto: IDataProduct,
    files: File[],
    links?: String[],
    as_copy?: number
  ) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });
      links?.forEach((link, index) => {
        formData.append(`link[${index}]`, link + '');
      });

      return (
        await Api.post(`${Endpoints.Product.main}/${produto.id}/attach/meta_image`, formData, {
          params: { as_copy },
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Products', handleErros);
      throw handleErros;
    }
  }

  static async importProducts(file: File, projeto_id?: number | null) {
    try {
      const formData = new FormData();
      formData.append(`produtos_importacao`, file);

      return (
        await Api.post(`${Endpoints.Product.main}/importar`, formData, {
          params: { projeto_id: projeto_id || null },
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Import Products', handleErros);
      throw handleErros;
    }
  }

  static async export(params?: IProductFilter, relation?: IDataRelation<ProductRelations>) {
    try {
      var resp;
      var nameFile = 'produtos_agnostico';
      if (relation) {
        resp = await Api.get(
          `${Endpoints[relation.relation].main}/${relation.id}/produto/exportar`,
          {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
            responseType: 'blob',
          }
        );
        nameFile = `produtos_loja_${relation.id}`;
      } else {
        resp = await Api.get(`${Endpoints.Product.main}/exportar`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
          responseType: 'blob',
        });
      }
      // Cria um URL temporário para o arquivo blob
      const downloadUrl = URL.createObjectURL(resp.data);

      // Cria um link e simula um clique para iniciar o download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = nameFile; // Nome do arquivo a ser baixado
      link.click();
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static async import(file: File, relation?: IDataRelation<ProductRelations>) {
    try {
      const formData = new FormData();
      formData.append(`produtos_importacao`, file);

      if (relation)
        return (
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/produto/importar`,
            formData
          )
        ).data;
      else return (await Api.post(`${Endpoints.Product.main}/importar`, formData)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Import Produtos', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Product.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Products', handleErros);
      throw handleErros;
    }
  }

  static async detachMetaImage(product_id: number, midia_id: number) {
    try {
      return (
        await Api.delete(`${Endpoints.Product.main}/${product_id}/detach/meta_image/${midia_id}`)
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Products', handleErros);
      throw handleErros;
    }
  }

  static async batch(data, params?) {
    try {
      return (await Api.put(`${Endpoints.Product.main}/curador/batch`, data, { params: params }))
        .data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      throw handleErros;
    }
  }

  static async fields() {
    try {
      return (await Api.get(`${Endpoints.Product.main}/campos`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      throw handleErros;
    }
  }

  static async sendToIntegrator(
    integrator: IDataIntegrator,
    data: { produtos: string | number[] | string[] },
    params?: IProductFilter
  ): Promise<IDataProduct> {
    try {
      const resp = (
        await Api.post(`${Endpoints.Integrator.main}/${integrator.id}/enviar/produtos`, data, {
          params: params,
        })
      ).data;
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Products', handleErros);
      throw handleErros;
    }
  }

  static async getFromAi(product: IDataProduct, field: string) {
    try {
      var resp;
      if (product?.id) {
        resp = await Api.get(`ia${Endpoints.Product.main}/${product.id}/${field}`);
      } else {
        resp = await Api.get(
          `ia${Endpoints.Product.main}/${field}?nm_produto=${product.nm_produto}`
        );
      }
      if (field === 'tags') {
        return resp?.data?.split(',') || [];
      }
      return typeof resp?.data === 'string' ? resp?.data : '';
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      throw handleErros;
    }
  }
  static async listWithNoAgnostic(
    params?: IProductFilter,
    relation?: IDataRelation<ProductRelations>
  ): Promise<IProductPagination | undefined> {
    try {
      if (!relation) return;
      const resp = (
        await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/produto/nao_base`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
      ).data;
      return resp as IProductPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }


  static getIds(relation: IDataRelation<any>): any {



    const id = relation.relation == 'Store' ? "loja_id"
      : relation.relation == 'Partner' ? "cliente_id"
        : relation.relation == 'Shopping' ? 'shopping_id'
          : relation.relation == 'Project' ? "projeto_id" : "uuid";

    let obj = {
      [id]: relation.id
    }
    if (relation.relationFather) {
      let relag = this.getIds(relation.relationFather);

      obj = { ...obj, ...relag }

    }

    return obj;

  }
  static async copyToBase(
    produtos: number[] | string[],
    relationsToCopy: string[],
    relation?: IDataRelation<ProductRelations>
  ) {
    try {
      if (!relation) return;

      let directions = this.getIds(relation)

      const resp = (
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/produto/base`, {
          produtos,
          relationsToClone: relationsToCopy,
          directions: directions
        })
      ).data;
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static async curate(store: IDataIDCurate): Promise<IDataIDCurate> {
    try {
      return await Api.post(`${Endpoints.Store.main}/${store.loja_id}/produto/curar`, {
        id: store.id,
      });
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Store', handleErros);
      throw handleErros;
    }
  }
}
