import { yupResolver } from '@hookform/resolvers/yup';
//import { LoadingButton } from '@mui/lab';
import { Autocomplete, Grid, styled, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Sku_Api from 'app/api/Sku_Api';
import AlertMessage from 'app/components/Alert';
import SelectIntegrator from 'app/components/Inputs/Selects/SelectIntegrator/SelectIntegrator';
import Toast from 'app/components/Toast';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IDataSku, IDataSkuErrors } from 'app/types/data/IDataSku';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { initialValues } from '..';
import { SkuSchema } from '../../@Schemas/SkuSchema';
import { IFormSku } from '../../@Types/IFormSku';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const UPDATE_MSG = getMessage('Sku', 'update');
const CREATE_MSG = getMessage('Sku', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const SkuIntegrationForm = (props: IFormSku) => {
  const [data, setData] = React.useState<IDataSku>(props.data || initialValues);
  //const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  useTrailHistory({
    selected: data,
    selectedName: data?.name,
    displayName: 'Gestão Integração',
    toggleView: () => {},
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm<IDataSku>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(SkuSchema),
  });

  React.useEffect(() => {
    setDataValue(props.data, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataSku) {
    //setLoading(true);
    try {
      var newData: IDataSku;
      if (data.id) {
        newData = await Sku_Api.update(data, props.relation);
      } else {
        newData = await Sku_Api.create(data, props.relation);
      }
      if (props.onSubmit) props.onSubmit();
      setData(newData);
      setDataValue(newData, setValue);
      setToast({
        open: true,
        message: data.id ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      //setLoading(false);
    }
  }

  return (
    <FormBox id="formik-Sku-Logistics" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/*Fields*/}
      <Grid container spacing={1} maxWidth={800}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="integrador_id"
            control={control}
            defaultValue={data?.integrador_id}
            render={({ field: { onChange } }) => (
              <SelectIntegrator
                id="integrador_id"
                disabled
                value={data?.integrador}
                onChange={(integrador) => {
                  setValue('integrador_id', integrador ? integrador.id : '');
                  setValue('integrador', integrador);
                  setData({
                    ...data,
                    integrador_id: integrador ? integrador.id : '',
                    integrador: integrador,
                  });
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="integrated_at"
            control={control}
            render={({ field: { onChange } }) => (
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  disabled
                  label="Dt. Atualização da Integração"
                  value={data?.integrated_at || null}
                  onChange={(newDate) => {
                    onChange(newDate);
                    setData({ ...data, integrated_at: newDate });
                  }}
                  renderInput={(props) => (
                    <TextField
                      label="Dt. Atualização da Integração"
                      id="mui-pickers-date"
                      sx={{ width: '100%' }}
                      size="small"
                      helperText={
                        <Typography color="red" variant="caption" component="span">
                          {errors.integrated_at?.message}
                        </Typography>
                      }
                      {...props}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="integration_status"
            control={control}
            defaultValue={data.integration_status}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disabled
                options={[
                  { value: 'new', label: 'Produto Novo' },
                  { value: 'updated_price', label: 'Produto Atualizado Preço' },
                  { value: 'updated_stock', label: 'Produto Atualizado Estoque' },
                  { value: 'updated_price_stock', label: 'Produto Atualizado Preço e Estoque' },
                ]}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.value || option.value === value
                }
                defaultValue={data.integration_status}
                value={data.integration_status}
                onChange={(event, option: any) => {
                  if (option) {
                    setData({ ...data, integration_status: option });
                  }
                  onChange(option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="integration_status"
                    label="Status da Integração"
                    type="text"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.integration_status?.message}
                      </Typography>
                    }
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="integration_situation"
            control={control}
            defaultValue={data.integration_situation}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disabled
                options={[
                  { value: 'received', label: 'Recebido' },
                  { value: 'imported_worksheet', label: 'Importado via planilha' },
                  { value: 'sent', label: 'Enviado' },
                  { value: 'exported_worksheet', label: 'Exportado via planilha' },
                ]}
                /* isOptionEqualToValue={(option, value) =>
                  option.value === value.value || option.value === value
                } */
                defaultValue={data.integration_situation}
                value={data.integration_situation}
                onChange={(event, option: any) => {
                  if (option) {
                    setData({ ...data, integration_situation: option });
                  }
                  onChange(option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="integration_situation"
                    label="Situação da integração"
                    type="text"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.integration_situation?.message}
                      </Typography>
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>

      {/*Buttons*/}
      {/* <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.onCancel} color="primary">
          Voltar
        </Button>
        <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
          Salvar
        </LoadingButton>
      </Grid> */}
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default SkuIntegrationForm;
