import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Paper,
  Popover,
  Tooltip,
  styled,
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import React from 'react';
import FilterForm from '../Forms/Filters';
import MassCurator from '../Forms/MassCurator';
import CopyToBase from './CustomIcons/CopyTobase';
import toolbarIconList from './ToolbarIconList';

const PaperWrapper = styled(Paper)(({ theme, mode }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2),
  marginBottom: theme.spacing(1),
}));

const customItems = [
  'filter',
  'mass_curator',
  'download_media',
  'filter_by_agnostic',
  'copy_to_base',
  'curate_process',
  'sku_curate',
];

const Toolbar = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const apiRef = props?.isGridRef && useGridApiContext();
  const itens = toolbarIconList({ ...props, apiRef });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [curatorAnchor, setCuratorAnchor] = React.useState(null);

  const isMassCuratorLoading = props?.options?.massCurator?.isMassCuratorLoading;
  const isDownloadingImage = props?.options?.isDownloadingImage;

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={
        props.sticky && props.options?.view?.state === 'grid'
          ? { position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 99 }
          : null
      }
    >
      <Box sx={{ flexGrow: 1 }}>
        <PaperWrapper elevation={1} square>
          {itens.map((item, key) => {
            if (!item.component && item.visible && !customItems.includes(item.type)) {
              return (
                <Tooltip title={item.title} key={key}>
                  <span>
                    <Button
                      disabled={item.disabled}
                      color="inherit"
                      sx={{ mr: 3, p: 0, minWidth: 'initial' }}
                      onClick={item.onClick}
                    >
                      <Icon>{item.icon}</Icon>
                      {item.children &&
                        item.children.map((childrenIcon, iconKey) => (
                          <Icon key={iconKey}>{childrenIcon.icon}</Icon>
                        ))}
                    </Button>
                  </span>
                </Tooltip>
              );
            } else if (item.type === 'filter' && item.visible) {
              const open = Boolean(anchorEl);
              const id = open ? 'simple-popover' : undefined;
              return (
                <React.Fragment key={key}>
                  <Tooltip title={item.title}>
                    <Button
                      //disabled={item.disabled}
                      color="inherit"
                      sx={{ mr: 3, p: 0, minWidth: 'initial' }}
                      onClick={handleFilterClick}
                    >
                      <Icon>{item.icon}</Icon>
                      {item.children &&
                        item.children.map((childrenIcon, iconKey) => (
                          <Icon key={iconKey}>{childrenIcon.icon}</Icon>
                        ))}
                    </Button>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                    /* sx={{
                      zIndex: 1301,
                    }} */
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <FilterForm
                      fields={props.options?.filter?.columns}
                      onFilterApply={props.options?.filter?.onFilter}
                    />
                  </Popover>
                </React.Fragment>
              );
            } else if (item.type === 'download_media' && item.visible) {
              return (
                <React.Fragment key={key}>
                  {!isDownloadingImage ? (
                    <Tooltip title={item.title}>
                      <Button
                        disabled={item.disabled}
                        color="inherit"
                        sx={{ mr: 3, p: 0, minWidth: 'initial' }}
                        onClick={item.onClick}
                      >
                        <Icon>{item.icon}</Icon>
                        {item.children &&
                          item.children.map((childrenIcon, iconKey) => (
                            <Icon sx={{ fontSize: '18px !important' }} key={iconKey}>
                              {childrenIcon.icon}
                            </Icon>
                          ))}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Baixando...">
                      <CircularProgress size={20} sx={{ mr: 3, p: 0, minWidth: 'initial' }} />
                    </Tooltip>
                  )}
                </React.Fragment>
              );
            } else if (item.type === 'mass_curator' && item.visible) {
              const open = Boolean(curatorAnchor);
              const id = open ? 'mass_curator' : undefined;
              return (
                <>
                  {!isMassCuratorLoading ? (
                    <React.Fragment key={key}>
                      <Tooltip title={item.title}>
                        <Button
                          disabled={item.disabled}
                          color="inherit"
                          sx={{ mr: 3, p: 0, minWidth: 'initial' }}
                          onClick={(event) => {
                            setCuratorAnchor(event.currentTarget);
                          }}
                        >
                          <Icon>{item.icon}</Icon>
                          {item.children &&
                            item.children.map((childrenIcon, iconKey) => (
                              <Icon key={iconKey}>{childrenIcon.icon}</Icon>
                            ))}
                        </Button>
                      </Tooltip>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={curatorAnchor}
                        onClose={() => setCuratorAnchor(null)}
                        sx={{ zIndex: 7 }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <MassCurator
                          saveMassCurator={(curadorId) =>
                            props?.options?.massCurator?.saveMassCurator(curadorId)
                          }
                          closePopover={() => setCuratorAnchor(null)}
                        />
                      </Popover>
                    </React.Fragment>
                  ) : (
                    <Tooltip title="Salvando...">
                      <CircularProgress size={20} sx={{ mr: 3, p: 0, minWidth: 'initial' }} />
                    </Tooltip>
                  )}
                </>
              );
            } else if (item.type === 'filter_by_agnostic' && item.visible) {
              return (
                <React.Fragment key={key}>
                  {!props?.options?.filter_by_agnostic?.isFilteringByAgnostic ? (
                    <Tooltip title={item.title}>
                      <Button
                        disabled={item?.disabled}
                        color="inherit"
                        sx={{ mr: 3, p: 0, minWidth: 'initial' }}
                        onClick={() =>
                          props?.options?.filter_by_agnostic &&
                          props.options.filter_by_agnostic.filterByAgnostic()
                        }
                      >
                        <Icon>{item.icon}</Icon>
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Filtrando...">
                      <CircularProgress size={20} sx={{ mr: 3, p: 0, minWidth: 'initial' }} />
                    </Tooltip>
                  )}
                </React.Fragment>
              );
            } else if (item.type === 'copy_to_base' && item.visible) {
              return <CopyToBase item={item} key={key} toolBarProps={props} />;
            } else if (item.visible) {
              return item.component;
            } else {
              return null;
            }
          })}
        </PaperWrapper>
      </Box>
    </div>
  );
};

export default Toolbar;
