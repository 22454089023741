import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, InputAdornment, styled, Typography } from '@mui/material';
import Sku_Api from 'app/api/Sku_Api';
import AlertMessage from 'app/components/Alert';
import NumberInput from 'app/components/Inputs/NumberInput/NumberInput';
import Toast from 'app/components/Toast';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IDataSku, IDataSkuErrors } from 'app/types/data/IDataSku';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { initialValues } from '..';
import { SkuSchema } from '../../@Schemas/SkuSchema';
import { IFormSku } from '../../@Types/IFormSku';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const UPDATE_MSG = getMessage('Sku', 'update');
const CREATE_MSG = getMessage('Sku', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const SkuLogisticsForm = (props: IFormSku) => {
  const [data, setData] = React.useState<IDataSku>(props.data || initialValues);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  useTrailHistory({
    selected: data,
    selectedName: data?.name,
    displayName: 'Gestão Logística',
    toggleView: () => {},
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm<IDataSku>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(SkuSchema),
  });

  React.useEffect(() => {
    setDataValue(props.data, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataSku) {
    setLoading(true);
    try {
      var newData: IDataSku;
      if (data.id) {
        newData = await Sku_Api.update(data, props.relation);
      } else {
        newData = await Sku_Api.create(data, props.relation);
      }
      if (props.onSubmit) props.onSubmit();
      setData(newData);
      setDataValue(newData, setValue);
      setToast({
        open: true,
        message: data.id ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormBox id="formik-Sku-Logistics" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/*Fields*/}
      <Grid container spacing={1} maxWidth={800}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="nr_largura_logistica"
            control={control}
            defaultValue={initialValues.nr_largura_logistica}
            render={({ field: { onChange } }) => (
              <NumberInput
                id="nr_largura_logistica"
                label="Largura"
                type="text"
                value={data.nr_largura_logistica}
                autoComplete={'off'}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.nr_largura_logistica?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, nr_largura_logistica: parseFloat(e.target.value) });
                  onChange(e);
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="nr_altura_logistica"
            control={control}
            defaultValue={initialValues.nr_altura_logistica}
            render={({ field: { onChange } }) => (
              <NumberInput
                id="nr_altura_logistica"
                label="Altura"
                type="text"
                value={data.nr_altura_logistica}
                autoComplete={'off'}
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.nr_altura_logistica?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, nr_altura_logistica: parseFloat(e.target.value) });
                  onChange(e);
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="nr_profundidade_logistica"
            control={control}
            defaultValue={initialValues.nr_profundidade_logistica}
            render={({ field: { onChange } }) => (
              <NumberInput
                id="nr_profundidade_logistica"
                label="Profundidade"
                type="text"
                value={data.nr_profundidade_logistica}
                autoComplete={'off'}
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.nr_profundidade_logistica?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, nr_profundidade_logistica: parseFloat(e.target.value) });
                  onChange(e);
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="nr_peso_logistica"
            control={control}
            defaultValue={initialValues.nr_peso_logistica}
            render={({ field: { onChange } }) => (
              <NumberInput
                id="nr_peso_logistica"
                label="Peso"
                type="text"
                value={data.nr_peso_logistica}
                autoComplete={'off'}
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.nr_peso_logistica?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, nr_peso_logistica: parseFloat(e.target.value) });
                  onChange(e);
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">g</InputAdornment>,
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.onCancel} color="primary">
          Voltar
        </Button>
        <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
          Salvar
        </LoadingButton>
      </Grid>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default SkuLogisticsForm;
