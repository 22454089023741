import FormContainer from 'app/components/Containers/FormContainer';
import FormSegmentation from 'app/components/Forms/Segmentation';
import SegmentationContext from 'app/contexts/SegmentationContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataSegmentation, SegmentationRelations } from 'app/types/data/IDataSegmentation';
import DisplayCategoriesAttribute from 'app/views/curated/category_attribute/components/DisplayCategoriesAttribute';
import DisplaySegmentationTypes from 'app/views/curated/segmentation_types/components/DisplaySegmentationTypes';
import React from 'react';

declare interface ISegmentationFormContainer {
  data?: IDataSegmentation;
  relation?: IDataRelation<SegmentationRelations>;
  onClickAttach?: () => void;
  onSubmit?: (segmentation?: IDataSegmentation) => void;
  cancel: () => void;
  refreshGrid?: () => void;
  setSelected: (data) => void;
  uuid?: string;
}

const SegmentationFormContainer = (props: ISegmentationFormContainer) => {
  const [data, setData] = React.useState<IDataSegmentation | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <SegmentationContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormSegmentation
            data={data}
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
            refreshGrid={props?.refreshGrid}
          />
        }
        tabs={
          data && [
            {
              title: 'Árvore de Categorias',
              component: (
                <DisplayCategoriesAttribute
                  displayName="Árvore de Categorias"
                  view="tree"
                  relation={{ id: data.id as number, relation: 'Segmentation' }}
                />
              ),
            },
            {
              title: 'Tipos',
              component: (
                <DisplaySegmentationTypes
                  relation={{ id: data.id as number, relation: 'Segmentation' }}
                />
              ),
            },
          ]
        }
      />
    </SegmentationContext.Provider>
  );
};

export default SegmentationFormContainer;
