import FormContainer from 'app/components/Containers/FormContainer';
import { LocalsForm } from 'app/components/Forms/@Types/IFormAttribute';
import AttributeForm from 'app/components/Forms/Attributes';
import { IDataRelation } from 'app/types/data/IData';
import { AttributeRelations, IDataAttribute } from 'app/types/data/IDataAttribute';
import { IDataPartner } from 'app/types/data/IDataPartner';
import React from 'react';

declare interface IAttributeFormContainer {
  data?: IDataAttribute;
  relation?: IDataRelation<AttributeRelations>;
  partner?: IDataPartner;
  onClickAttach?: () => void;
  onSubmit: (data?) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  local?: LocalsForm;
}

const AttributeFormContainer = (props: IAttributeFormContainer) => {
  const [data, setData] = React.useState<IDataAttribute | undefined>(props.data);
  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <AttributeForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
            partner={props.partner}
            local={props.local}
          />
        }
      />
    </>
  );
};

export default AttributeFormContainer;
