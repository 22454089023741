import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, styled, TextField, Typography } from '@mui/material';
import Units_Api from 'app/api/Units_Api';
import AlertMessage from 'app/components/Alert';
import TimedTextField from 'app/components/Inputs/TimedTextField/TimedTextField';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataUnit, IDataUnitErrors } from 'app/types/data/IDataUnit';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UnitSchema } from '../@Schemas/UnitSchema';
import { IFormUnit } from '../@Types/IFormUnit';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataUnit = {
  id: null,
  sigla_unidade: '',
  ds_unidade: '',
};

const UPDATE_MSG = getMessage('Unidade', 'update');
const CREATE_MSG = getMessage('Unidade', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const UnitForm = (props: IFormUnit) => {
  const { resetFormList } = useUtilsContext();

  const [data, setData] = React.useState<IDataUnit>(props.data || initialValues);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataUnit>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataUnit> | null>(null);
  const [comboLoading, setComboLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => {
    setData((props.data as IDataUnit) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.unitForm]);

  React.useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]);

  async function load() {
    setComboLoading(true);
    try {
      const items = await Units_Api.list({ q: search });

      const combo: ISelectOption<IDataUnit>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.sigla_unidade,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataUnitErrors | unknown) {
      if (error) {
        const err = error as IDataUnitErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    reset,
    clearErrors,
  } = useForm<IDataUnit>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(UnitSchema),
  });

  async function onSubmit(data: IDataUnit) {
    setLoading(true);
    try {
      var newData: IDataUnit;
      if (data.id) {
        newData = await Units_Api.update(data, props.relation);
      } else {
        newData = await Units_Api.create(data, props.relation);
        if (props.onSubmit) props.onSubmit();
      }
      setData(newData);
      setValue('id', newData.id);
      setDataValue(newData, setValue);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataUnitErrors | unknown) {
      if (error) {
        const err = error as IDataUnitErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }
  return (
    <FormBox id="formik-Unit" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/*Fields*/}
      <Grid container spacing={1} maxWidth={800}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>
        {props.relation && (
          <>
            <Grid item xs={12} marginBottom={2}>
              <Autocomplete
                noOptionsText="Nenhuma unidade"
                options={comboData}
                defaultValue={comboOption}
                value={comboOption}
                onClose={() => setSearch('')}
                loading={comboLoading}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(event, option) => {
                  if (option && option.data) {
                    setData(option.data);
                    setComboOption(option);
                    setDataValue(option.data, setValue);
                  } else {
                    setData(initialValues);
                    setComboOption(null);
                    setDataValue(initialValues, setValue);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography noWrap>
                      [{option.value}] {option.label}
                    </Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TimedTextField
                    {...params}
                    label="Unidades"
                    placeholder="Pesquisar uma unidade"
                    type="text"
                    size="small"
                    onChange={(e) => setSearch(e.target.value)}
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.id?.message}
                      </Typography>
                    }
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={2}>
          <Controller
            name="id"
            control={control}
            defaultValue={data.id}
            render={({ field: { onChange } }) => (
              <TextField
                id="id"
                label="ID"
                type="text"
                disabled={true}
                value={data.id}
                autoComplete={'off'}
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: Boolean(data.id) }}
                onChange={(e) => {
                  setData({ ...data, id: parseInt(e.target.value) });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={10}>
          <Controller
            name="sigla_unidade"
            control={control}
            defaultValue={data.sigla_unidade}
            render={({ field: { onChange } }) => (
              <TextField
                id="sigla_unidade"
                label="Sigla*"
                type="text"
                value={data.sigla_unidade}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 4,
                }}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: Boolean(data.sigla_unidade) }}
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.sigla_unidade?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, sigla_unidade: e.target.value });
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="ds_unidade"
            control={control}
            defaultValue={data.ds_unidade}
            render={({ field: { onChange } }) => (
              <TextField
                id="ds_unidade"
                label="Descrição*"
                type="text"
                value={data.ds_unidade}
                autoComplete={'off'}
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{ shrink: Boolean(data.ds_unidade) }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.ds_unidade?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, ds_unidade: e.target.value });
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.onCancel} color="primary">
          Voltar
        </Button>
        <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
          Salvar
        </LoadingButton>
      </Grid>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default UnitForm;
