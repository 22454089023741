import Workflow_Api from 'app/api/Workflow_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import WorkflowForm from 'app/components/Forms/Workflow';
import WorkflowContext from 'app/contexts/WorkflowContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataWorkflow, WorkflowRelations } from 'app/types/data/IDataWorkflow';
import DisplayActivity from 'app/views/partners/activity/components/DisplayActivity';
import ActivityKanban from 'app/views/partners/activity/components/Kanban';
import DisplayWorkflowPhases from 'app/views/partners/workflow_phases/components/DisplayWorkflowPhases';
import React from 'react';
import GanttWorkflow from '../../../activity/components/GanttWorkflow';

declare interface IWorkflowFormContainer {
  data?: IDataWorkflow;
  relation?: IDataRelation<WorkflowRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid: string;
}

const WorkflowFormContainer = (props: IWorkflowFormContainer) => {
  const [data, setData] = React.useState<IDataWorkflow | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <WorkflowContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <WorkflowForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        tabs={
          !data?.id
            ? undefined
            : [
                {
                  title: 'Etapas',
                  component: (
                    <DisplayWorkflowPhases
                      relation={{ id: data.id as number, relation: 'Workflow' }}
                    />
                  ),
                },
                {
                  title: 'Atividades',
                  component: (
                    <DisplayActivity
                      displayName="Atividades"
                      relation={{ id: data.id as number, relation: 'Workflow' }}
                      view="grid"
                    />
                  ),
                },
                {
                  title: 'Árvore de Atividades',
                  component: (
                    <DisplayActivity
                      displayName="Árvore de Atividades"
                      relation={{ id: data.id as number, relation: 'Workflow' }}
                      view="tree"
                    />
                  ),
                },
                {
                  title: 'Quadro',
                  component: (
                    <ActivityKanban relation={{ id: data.id as number, relation: 'Workflow' }} />
                  ),
                },
                {
                  title: 'Cronograma',
                  component: (
                    <GanttWorkflow relation={{ id: data.id as number, relation: 'Workflow' }} />
                  ),
                },
                /* {
              title: 'Orientações',
              component: (
                <DisplayOrientation
                  relation={{ id: data.id as number, relation: 'Workflow' }}
                />
              ),
            }, */
                {
                  title: 'Anexos',
                  component: (
                    <GridAttachments
                      relationData={data}
                      apiModel={Workflow_Api}
                      onClickAttach={props.onClickAttach}
                    />
                  ),
                },
              ]
        }
      />
    </WorkflowContext.Provider>
  );
};

export default WorkflowFormContainer;
